import React from "react";
import Navbar from "./Components/Navbar";
import Hero from "./Components/Hero";
import Faq from "./Components/Faq";
import Footer from "./Components/Footer";
import myVideo from "./assets/video/video.mp4";
import Partners from "./Components/Partners";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { WagmiProvider, createConfig, http } from "wagmi";
import { walletConnect } from "wagmi/connectors";
import { createWeb3Modal } from "@web3modal/wagmi/react";
import { Toaster } from "sonner";

const nordek = {
  id: 81041,
  name: "Nordek",
  network: "nordek",
  iconUrl: `https://pbs.twimg.com/profile_images/1721104975164751872/DTsj6Zth_400x400.jpg`,
  nativeCurrency: {
    decimals: 18,
    name: "Nordek",
    symbol: "NRK",
  },
  rpcUrls: {
    public: { http: ["https://mainnet-rpc.nordekscan.com"] },
    default: { http: ["https://mainnet-rpc.nordekscan.com"] },
  },
  blockExplorers: {
    default: { name: "Nordekscan", url: "https://nordekscan.com:443" },
  },
  testnet: false,
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retryOnMount: false,
    },
  },
});

const projectId = "ae046688913a7d4c2d2e586617fa6fe6";

const metadata = {
  name: "Starnest Exchange",
  url: "https://presale-kurumi.meme",
  icons: [],
};

const chains = [nordek];

const config = createConfig({
  chains,
  transports: {
    [nordek.id]: http(),
  },
  connectors: [
    walletConnect({
      projectId,
      metadata,
      disableProviderPing: true,
    }),
  ],
});

createWeb3Modal({
  wagmiConfig: config,
  projectId,
  metadata,
  allWallets: "SHOW",
  featuredWalletIds: [
    "1ae92b26df02f0abca6304df07debccd18262fdf5fe82daa81593582dac9a369", // Rainbow
    "fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa", // Coinbase
  ],
});

const App = () => {
  return (
    <WagmiProvider config={config} reconnectOnMount>
      <QueryClientProvider client={queryClient}>
        <div>
          <div class="w-embed">
            <video autoPlay loop muted class="video-bg">
              <source src={myVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className=" relative flex flex-col gap-5 md:gap-12 p-3 md:p-7 max-w-[1300px] mx-auto !pb-28 z-20">
            <Navbar />
            <Hero />
            <Partners />
            {/* <Faq /> */}
            <Footer />
          </div>
        </div>
        <Toaster richColors />
      </QueryClientProvider>
    </WagmiProvider>
  );
};

export default App;
