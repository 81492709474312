import React, {useState,useEffect,useMemo} from 'react'
import Nrkcoin from '../assets/images/Nrkcoin.png';
import Starcoin from '../assets/images/star2.svg';
import Coin from '../assets/images/star2.svg';
import useGetNordekPrice from "../hooks/useGetNordekPrice";
import {useGetKuromiMinted} from "../hooks/useGetKuromiMinted";
import {useGetNRKMinted} from "../hooks/useGetNrkMinted";
import {useSaveTransaction} from "../hooks/useSaveTransaction";
import { useDebounceCallback } from "usehooks-ts";
import { formatUnits, parseEther } from "viem";
import { sntAbi } from "../assets/abi/contract-abi";
import clsx from "clsx";
import { useAccount, useBalance, useChainId, useWriteContract } from "wagmi";
import { toast } from "sonner";
import { useQueryClient } from "@tanstack/react-query";

// import LandingPage from "./Comingsoon";

const kuromiPriceInUSD = 1.25;
const SNTPriceInUSD = 1.25;

const convertNrkToKuromi = (nrkAmount, nrkUSDPrice) => {
  const nrkAmountInUSD = nrkAmount * nrkUSDPrice;

  return nrkAmountInUSD / kuromiPriceInUSD;
};
const convertSntToNrk = (sntAmount,nordekLivePrice) => {
  const sntAmountInUSD = sntAmount * SNTPriceInUSD;
  return  sntAmountInUSD/nordekLivePrice;
};

const contractAddress = "0xA95277Bf3d5C4c7dC31FC9496Ed7e184E11d94B8";

const Hero = () => {

  const [nrkAmount, setNrkAmount] = useState(1);
  const [kuromiAmount, setKuromiAmount] = useState(0);
  const [SNTAmount, setSNTAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const queryClient = useQueryClient();

  const { address, isConnecting, isConnected } = useAccount();
  const chainId = useChainId();
  
  const {
    data: balanceData,
    isFetching,
    isStale: isBalanceStale,
    refetch: refetchBalance,
  } = useBalance({
    address,
    token: '0xe3deAA063803C6F4d7B9fEcF346dd8CeEE115Db3', 
    query: { refetchInterval: 1 * 60 * 1000, staleTime: 1 * 60 * 1000 },
  });

  //console.log(balanceData);

  const {
    data: nordekPrice,
    isPending: fetchingNordekPrice,
    isStale: isNordekPriceStale,
    refetch: refetchNordekPrice,
  } = useGetNordekPrice();

  useEffect(() => {
    if (nordekPrice) {
      //const minimumPurchaseLimit = 12 / nordekPrice;
      const minimumPurchaseLimit = 15 / SNTPriceInUSD;
      if (nrkAmount < minimumPurchaseLimit) {
        setSNTAmount(minimumPurchaseLimit);
        //setKuromiAmount(convertNrkToKuromi(minimumPurchaseLimit, nordekPrice));
        setNrkAmount(convertSntToNrk(minimumPurchaseLimit,nordekPrice));
      } else {
        //setKuromiAmount(convertNrkToKuromi(nrkAmount, nordekPrice));
        setNrkAmount(convertSntToNrk(SNTAmount,nordekPrice));
      }
    }
  }, [nordekPrice]);

  const { data: kuromiMinted } = useGetKuromiMinted({ address });
  const { data: NRKMinted } = useGetNRKMinted({ address });

  const { mutateAsync: saveTransaction } = useSaveTransaction();

  const { writeContractAsync } = useWriteContract();

  const debounceRefetchNordekPrice = useDebounceCallback(
    refetchNordekPrice,
    2000
  );

  const handleChange = (e) => {
    debounceRefetchNordekPrice();

    if (e.target.name === "snt") {
      setSNTAmount(e.target.value);
      setNrkAmount(convertSntToNrk(e.target.value, nordekPrice));
    }
  };

  const handleConvert = async () => {
    let transactionHash;
    let isSufficientBalance = hasSufficientBalance;
    let nordekCurrentPrice = nordekPrice;
    let kuromiMintableAmount = kuromiAmount;
    let nrkMintableAmount = nrkAmount;
    let nrkSpendAmount = nrkAmount;
    let sntSpendAmount = SNTAmount;

    if (!nordekCurrentPrice) {
      setIsLoading(false);
      toast.error("Failed to fetch NRK price data");
      return;
    }

    if (!isConnected) {
      setIsLoading(false);
      toast.error("Please connect your wallet");
      return;
    }

    if (!nrkMintableAmount) {
      setIsLoading(false);
      toast.error("Failed to calculate Kuromi amount");
      return;
    }

    if (!isPurchasingMinimum) {
      toast.error(`Minimum purchase limit is ${minimumPurchaseLimit}`);
      return;
    }

    if (nrkAmount <= 0) {
      toast.error("Invalid amount");
      return;
    }

    setIsLoading(true);

    if (isNordekPriceStale) {
      nordekCurrentPrice = await refetchNordekPrice();
      //kuromiMintableAmount = convertSntToNrk(
      nrkMintableAmount = convertSntToNrk(
        sntSpendAmount,
        nordekCurrentPrice
      );
      ///setKuromiAmount(kuromiMintableAmount);
      setNrkAmount(nrkMintableAmount);
    }

    if (!balanceData || isBalanceStale) {
      try {
        const { data: balanceInfo } = await refetchBalance();
        console.log("balanceData",balanceInfo);
        if (balanceInfo) {
          const formattedBalanceString = formatUnits(
            balanceInfo.value,
            balanceInfo.decimals
          );

          const balance = parseFloat(formattedBalanceString);
        console.log("balance",balance);

          isSufficientBalance = balance >= SNTAmount;
          console.log("nrkAmount",nrkAmount);

        }
      } catch (error) {
        console.log("some error",error);
        setIsLoading(false);
        toast.error("Failed to fetch balance");
        return;
      }
    }
    console.log("SNTAmount",SNTAmount);

    if (!isSufficientBalance) {
      setIsLoading(false);
      toast.error("Insufficient balance");
      return;
    }

    try {
      transactionHash = await writeContractAsync({
        abi: sntAbi,
        address: contractAddress,
        chainId,
        functionName: "swapStarnestForNRK",
        //value: parseEther(nrkSpendAmount.toString()),
        args: [
          parseEther(sntSpendAmount.toString()),
          parseEther(nrkSpendAmount.toString()),
        ]
      });
    } catch (error) {
      console.error("Error converting", error);
      setIsLoading(false);
      toast.error("Error processing transaction. Please try again.");
      return;
    }

    await saveTransaction(
      {
        address,
        nrkAmount,
        nordekPrice,
        kuromiMinted: kuromiMintableAmount,
        transactionHash,
        nrkCurrentValue: nordekCurrentPrice,
      },
      {}
    );

    //setNrkAmount(1);
    setSNTAmount(1);
    setKuromiAmount(convertSntToNrk(1, nordekCurrentPrice));
    setIsLoading(false);
    toast.success("Transaction successful");
    await queryClient.invalidateQueries({
      queryKey: ["getKuromiMinted", address],
    });
  };

  const availableBalance = useMemo(() => {
    if (!balanceData) return 0;

    const formattedBalanceString = formatUnits(
      balanceData.value,
      balanceData.decimals
    );

    return parseFloat(formattedBalanceString);
  }, [balanceData]);

  const minimumPurchaseLimit = 15 / SNTPriceInUSD;

  const hasSufficientBalance = availableBalance >= SNTAmount;

  const isPurchasingMinimum = nrkAmount >= minimumPurchaseLimit;

  const nrkAmountInUSD = nrkAmount * (nordekPrice ?? 0);
  console.log("SNTAmount",SNTAmount);


  return (
    <div>
    {/* <div className="flex flex-col gap-2.5 md:gap-5 md:flex-row md:items-start mb-10">
        <div className="flex md:w-full">
          <div className="flex flex-col md:flex-row items-center justify-center gap-1.5 md:gap-4 w-full p-5 md:p-6 md:px-0 bg-[#aea8ff87] rounded-2xl">
            <div className="container">
              <h1 className="uppercase text-center mb-2 bg-gradient-to-r font-extrabold text-2xl from-[#FFD600] to-[#FFEC86]/90 text-transparent bg-clip-text">
                Pre-Sale Will Go Live
              </h1>
              <LandingPage targetDate="2024-03-03T17:00:00" />
            </div>
          </div>
        </div>
    </div> */}
    <div className="flex flex-col sm:flex-row gap-5 md:gap-8 md:w-full" id="hero">      
      <div className="flex item-center m-auto flex-col gap-2.5 fade-in-left md:w-1/2">
        <img src={Coin} className='w-55 m-auto'/>        
        <h1 className="text-center text-3xl font-extrabold italic uppercase md:text-5xl md:text-center md:w-full ">
          STARNEST
        </h1>
        <p className="text-center text-sm md:text-center md:text-xl md:w-full font-light">        
          <span className="font-semibold">
            A cosmic galaxy where your NFTs come to life, blending real estate, staking, and token minting.
          </span> 
        </p>
      </div>     
    
      <div className="flex flex-col gap-2.5 md:gap-5 md:flex-row justify-center md:w-1/2">
          <div className="flex flex-col gap-5 md:w-3/4">
            <div className="bg-[#080531] rounded-2xl" id="presale-form">
              <div className="p-5 md:p-8 space-y-5">
                <div className="space-y-5 text-left">
                  {address && (
                    <p className="text-sm text-white break-words">
                      Address Connected = {address}
                    </p>
                  )}

                  {Boolean(NRKMinted) && (
                    <div className="border border-white p-2 rounded-xl">
                      <p className="text-sm text-white">
                        NRK Purchased = {NRKMinted} Kuromi
                      </p>
                    </div>
                  )}

                  <p className="text-sm text-white/75">
                    Buying Price = ${kuromiPriceInUSD}
                  </p>

                  {Boolean(nordekPrice) && (
                    <p className="text-sm text-white/75">
                      NRK Price = ${nordekPrice}
                    </p>
                  )}

                  <p className="text-sm text-white/80">
                    Minimum Limit = $15 | {minimumPurchaseLimit} SNT
                  </p>

                  <hr className="border-white/10" />
                </div>
            
            <div className="space-y-2">
              <label
                for="from-token"
                className="text-xs font-semibold uppercase text-white"
              >
                Amount in <span className="text-primary">$SNT</span> you pay
              </label>
              <div className="relative">
                <input
                  type="number"
                  className={clsx(
                    "w-full p-5 pr-16 bg-transparent border rounded-lg border-white/20 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-primary mb-2",
                    {
                      "border-red-500": !isPurchasingMinimum,
                    }
                  )}
                  name="snt"
                  value={SNTAmount}
                  onChange={handleChange}
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-5">
                  <img
                    src={Starcoin}
                    alt="SNT"
                    className="object-contain w-6 h-6"
                  />
                </div>
              </div>
            </div>
            <div className="space-y-2">
              <label
                for="from-token"
                className="text-xs font-semibold uppercase text-white"
              >
                Amount in $NRK you receive 
              </label>
              <div className="relative">
                <input
                  type="number"
                  disabled
                      value={nrkAmount}
                      onChange={handleChange}
                      className="w-full p-5 pr-16 bg-transparent border rounded-lg border-white/20 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-primary"
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-5">
                  <img
                    src={Nrkcoin}
                    alt="NRK"
                    className="object-contain w-9 h-9"
                  />
                </div>
              </div>
            </div>
            <button
                  className="flex items-center bg-[#8554FB] justify-center w-full px-3 py-5 font-semibold text-white uppercase rounded-lg bg-primary hover:bg-primary/80 disabled:opacity-50 disabled:cursor-not-allowed"
                  disabled={
                    isConnecting ||
                    isFetching ||
                    !hasSufficientBalance ||
                    fetchingNordekPrice ||
                    isLoading ||
                    !isConnected ||
                    !isPurchasingMinimum
                  }
                  onClick={handleConvert}
                >
                    <>
                      {isConnecting ||
                      isFetching ||
                      fetchingNordekPrice ||
                      isLoading ? (
                        <>
                          <span className="flex">
                            <svg
                              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          </span>
                          <span className="text-sm font-medium">
                            {isConnecting && "Connecting ....."}
                            {isFetching && "Fetching Balance ....."}
                            {fetchingNordekPrice && "Fetching Price Data ....."}
                            {isLoading && "Processing Transaction ....."}
                          </span>
                        </>
                      ) : (
                        <>
                          {hasSufficientBalance
                            ? isPurchasingMinimum
                              ? "Purchase Kuromi"
                              : "Minimum Purchase Limit Not Met"
                            : "Insufficient Balance"}
                        </>
                      )}
                    </>
                 
                </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
 
  )
}

export default Hero;