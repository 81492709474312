import { useQuery } from "@tanstack/react-query";

export function useGetNRKMinted({ address }) {
  return useQuery({
    queryKey: ["getNRKMinted", address],
    enabled: !!address,
    queryFn: async () =>
      fetch(`https://exchange-api.starnest.io/api/get/${address}`).then((res) =>
        res.json()
      ),
    select: (data) => data?.sum,
  });
}
