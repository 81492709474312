import { useQuery } from "@tanstack/react-query";

export default function useGetNordekPrice() {
  return useQuery({
    queryKey: ["nordekPrice", "coinGecko"],
    queryFn: ({ signal }) =>
      fetch(
        "https://api.coingecko.com/api/v3/simple/price?ids=nordek&vs_currencies=usd",
        {
          signal,
        }
      ).then((res) => res.json()),
    select: (data) => {
      return data?.nordek?.usd;
    },
    staleTime: 1000 * 60 * 60 * 1,
    refetchInterval: 1000 * 60 * 60 * 1,
  });
}
