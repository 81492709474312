import React from "react";
import Telegram from "../assets/images/telegram.png";
import Twitter from "../assets/images/twitter.png";
const Footer = () => {
  return (
    <div className="flex flex-col items-center gap-8 mt-8">
      {/* <div className="px-4 py-2.5 text-white text-sm font-medium bg-[#363079] rounded-xl text-center">
        For all inquiries : 
        <a className="text-[#FFBD21]" href="mailto:contact@hypeloot.com">
          &nbsp;contact@starnest.io
        </a>
      </div> */}
      <div className="flex items-center justify-center gap-4">
        <a
          href="https://t.me/starnest_io"
          className="flex items-center justify-center" target="_blank"
        ><img src={Telegram} alt={"telegram"} /></a>
        <a
          href="https://twitter.com/Starnest_io"
          className="flex items-center justify-center" target="_blank"
        ><img src={Twitter} alt={"telegram"}/></a>
      </div>
      <div className="flex flex-wrap gap-5 justify-center items-center">        
        <a
          href="#"
          target="_blank"
          className="text-white text-xs font-medium"
        >          
          FAQ
        </a>
        <a
          href="#"
          target="_blank"
          className="text-white text-xs font-medium"
        >
          Terms of service
        </a>
      </div>
      <div className="text-center text-white text-[12px] font-medium">
        © 2024 StarNest • All Rights Reserved | Built In - Nordek
      </div>
    </div>
  );
};

export default Footer;
