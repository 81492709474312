import { useQuery } from "@tanstack/react-query";

export function useGetKuromiMinted({ address }) {
  return useQuery({
    queryKey: ["getKuromiMinted", address],
    enabled: !!address,
    queryFn: async () =>
      fetch(`https://presale-api.kuromi.meme/api/get/${address}`).then((res) =>
        res.json()
      ),
    select: (data) => data?.sum,
  });
}
