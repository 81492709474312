import React from "react";
import Card1 from "../assets/images/nordek.png"
import Card2 from "../assets/images/fastpay.png"
import Card3 from "../assets/images/norpay.svg"

const Partners = () => {
  const data = [
    {
     image: Card3
    },
    {
      image: Card2
    },
    {
     image: Card1
    },
  ];

  return (
    <div className="flex flex-col gap-5 md:gap-10 mt-10" id="partners">
     <h5 className="text-center text-3xl font-extrabold italic uppercase md:text-4xl">Our Partners</h5>
        <div className="grid grid-cols-2 gap-3 md:gap-5 md:grid-cols-3 items-center">
            {data.map((item) => {
            return (
            <div className=" flex items-center justify-center rounded-xl bg-[#363079] h-12 md:h-[92px]">
                <img src={item.image} className="h-[25px] md:h-[50px]" />
            </div>
            );
          })}
        </div>
    </div>

  );
};

export default Partners;
