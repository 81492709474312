import { useMutation } from "@tanstack/react-query";

export function useSaveTransaction() {
  return useMutation({
    mutationKey: ["saveTransaction"],
    mutationFn: async ({
      address,
      nrkAmount,
      nrkCurrentValue,
      kuromiMinted,
      transactionHash,
    }) =>
      fetch("https://presale-api.kuromi.meme/api/store", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          address,
          nrk: nrkAmount,
          nrk_value: nrkCurrentValue,
          kuromi: kuromiMinted,
          hash: transactionHash,
        }),
      }).then((res) => res.json()),
  });
}
