import React from "react";
import Logo from "../assets/images/logo.png"
// import Telegram from "../assets/images/telegram.png";
// import Twitter from "../assets/images/twitter.png";
// import documentPDF from '../assets/doc/whitepaper_starnest.pdf';
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount, useDisconnect } from "wagmi";
import clsx from "clsx";

const Navbar = () => {
  const { open } = useWeb3Modal();
  const { isConnected, isConnecting, isDisconnected } = useAccount();
  const { disconnect, isPending: isDisconnecting } = useDisconnect();

  const handleConnectWallet = async () => {
    if (isConnected) {
      disconnect();
    } else {
      open({ view: "Connect" });
    }
  };
  return (
    <div className="relative flex items-center justify-between z-30">
      <div className="flex items-center gap-1.5 md:gap-2">
        <img
          src={Logo}
          className=""
          alt="Coin Logo"
        />
      </div>
      {/* <div className="hidden md:flex items-center gap-5">
        <a href="hero" className="text-sm text-white hover:text-white">
          What is StartNest?
        </a>
        <a
          href={documentPDF}
          className="text-sm text-white hover:text-white"
          target="_blank"
        >
          Whitepaper
        </a>
        <a href="/#partners" className="text-sm text-white hover:text-white">
          Our Partners
        </a>
        <a href="/#how-to-buy" className="text-sm text-white/50 hover:text-white">
          How to Buy
        </a>
        <a href="/#faq" className="text-sm text-white hover:text-white">          
          FAQ
        </a>
      </div>
      <div className="hidden md:flex items-center justify-center gap-3">
        <a href="#" className="flex items-center justify-center">
          <img src={Telegram}  class="w-[20px] h-[20px]"/>
        </a>
        <a href="#" className="flex items-center justify-center">
          <img src={Twitter}  class="w-[20px] h-[20px]"/>
        </a>
      </div> */}
      <div className="relative">
      <div className="relative">
        <button
          className={clsx(
            "flex items-center bg-[#8554FB] px-3 md:px-5 py-1 md:py-2 gap-2 md:gap-2.5 rounded-md cursor-pointer disabled:cursor-not-allowed disabled:opacity-50",
            { "bg-red-500": isConnected }
          )}
          onClick={handleConnectWallet}
          disabled={isConnecting || isDisconnecting}
        >
          {isConnecting || isDisconnecting ? (
            <>
              <span className="flex">
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </span>
              <span className="text-sm font-medium">
                {isConnecting && "Connecting ....."}
                {isDisconnecting && "Disconnecting ....."}
              </span>
            </>
          ) : (
            <>
              {isConnected ? (
                <>
                  <span className="text-sm font-medium">Disconnect</span>
                  <span className="flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="lucide lucide-log-out"
                    >
                      <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                      <polyline points="16 17 21 12 16 7" />
                      <line x1="21" x2="9" y1="12" y2="12" />
                    </svg>
                  </span>
                </>
              ) : (
                <>
                  <span className="text-sm font-medium">Connect Wallet</span>
                  <span className="flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M13 18C13 17.45 12.55 17 12 17H3V11H21V5C21 3.9 20.1 3 19 3H3C1.89 3 1.01 3.89 1.01 5L1 17C1 18.11 1.89 19 3 19H12C12.55 19 13 18.55 13 18ZM19 7H3V5H19V7ZM19 21C18.45 21 18 20.55 18 20V18H16C15.45 18 15 17.55 15 17C15 16.45 15.45 16 16 16H18V14C18 13.45 18.45 13 19 13C19.55 13 20 13.45 20 14V16H22C22.55 16 23 16.45 23 17C23 17.55 22.55 18 22 18H20V20C20 20.55 19.55 21 19 21Z"
                        fill="white"
                      ></path>
                    </svg>
                  </span>
                </>
              )}
            </>
          )}
        </button>
      </div>
      </div>
    </div>
  );
};

export default Navbar;
